.filter-information-popup-container {
  width: 379px;
  background-color: white;
  top: 40px;
  right: 100px;
  height: calc(100vh * 0.7);
  border-radius: 10px;
  overflow: hidden;
  z-index: 100;

  label {
    cursor: pointer;
  }

  #collapseSearchResult {
    height: calc(100% - 20px);
  }

  div {
    .body-group {
      height: calc(100vh - 21vh);
      padding: 11px 11px 0 11px;

      .container-header-title {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        margin-bottom: 10px;
        padding: 0 10px;
        h6 {
          border-radius: 4px 4px 0 0;
          margin-bottom: -1px;
          padding-top: 5px;
        }

        .active {
          border-width: 1px 1px 0 1px;
          border-style: solid;
          border-color: rgba(0, 0, 0, 0.1);
          margin-bottom: -1px;
          background-color: white;
        }
      }

      .select-attributes-container {
        height: 100%;
        overflow-y: auto;
        max-height: 530px;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        padding-top: 5px;
      }

      .search-title {
        font-size: 15px;
        line-height: 1.42857143;
        color: #333;
        cursor: pointer;
      }

      .list-search-type {
        .active {
          background-color: #ffe400;
        }

        button {
          border: 1px solid rgba(112, 109, 109, 0.219);
          margin: 0 5px 10px 0;

          &:hover {
            background-color: rgba(199, 192, 192, 0.267);
          }

          img {
            width: 20px;
          }
        }
      }

      .search-with-attribute {
        .fa-attribute {
          cursor: pointer;
          border: 1px solid #ddd;
          border-radius: 50%;
          padding: 1px 5px;
          font-size: 14px;

          .fa-circle {
            color: #ffffff;
          }

          .fa-check {
            color: #555;
          }
        }

        span {
          font-size: 14px;
        }
      }
    }

    .footer-button-group {
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 50px;
      bottom: 0px;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      background-color: #ffffff;

      button {
        font-size: 14px;

        img {
          width: 12px;
          margin-right: 5px;
          vertical-align: baseline;
        }
      }
    }
  }
  .oNumber {
    display: inline;
    padding-left: 10px;
    li {
      padding: 0px 3px;
      border: 1px solid #ddd;
      border-radius: 2px;
      width: 20px;
      height: 20px;
      margin-left: 3px;
      display: inline;
      vertical-align: middle;
      cursor: pointer;
      &.active {
        background-color: lemonchiffon;
      }
    }
    .operator {
      color: #337ab7;
      font-size: 0.9em;
      font-weight: normal;
    }
  }
}
.left-print {
  width: 25%;
  float: left;
  padding: 0px 15px;
  position: absolute;
  z-index: 99;
  left: 0px;
  top: 50px;
  background: rgb(255, 255, 255);
  height: 624.2px;
  border: 1px solid #ddd;
}

//map measure
.ol-tooltip {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: white;
  opacity: 0.7;
  padding: 0px;
  white-space: nowrap;
  font-size: 12px;
}
.ol-tooltip-measure {
  opacity: 1;
  font-weight: bold;
}
//color popup measure
.ol-tooltip-static {
  background-color: #ffcc33;
  color: black;
  border: 1px solid white;
}
.ol-tooltip-measure:before,
.ol-tooltip-static:before {
  border-top: 6px solid rgba(0, 0, 0, 0.5);
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  position: absolute;
  bottom: -6px;
  margin-left: -7px;
  left: 50%;
}
.style-a {
  margin-left: 10px;
  margin-top: -10px;
}
