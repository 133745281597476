.general-planning-map-view-container {
    .left-custom-navbar {
        transition-duration: 0.5s;
        top: 0px;

        .custom-button-left-menu {
            top: 10px;
            left: 330px;
            background-color: #1075bd;
            padding: 6px 12px;

            img {
                width: 18px;
            }
        }

        .custom-sub-map-tools-panel {
            z-index: 50;
        }
    }

    .custom-position-filter-infomation-popup {
        top: 20px;
        right: 120px;
    }

    .map-tools-container {
        .map-tool-panel-container {
            top: 50px;
            right: 10px;
        }
    }
}