.planning-by-type-container {
    padding-top: 10px;
}

.list-footer {
    border-top: 1px solid #b6b3b3;
    padding-top: 1rem;
    padding-bottom: 1rem;

    .text-result {
      color: #c22e3c;
      font-weight: 600;
    }
  }