.tools-box-right-container {
    top: 10px;
    right: 10px;
    z-index: 50;
    display: flex;
    background-color: #ffffff;
    border-radius: 4px;
    height: 40px;
    align-items: center;

    button {
        border: 0;
        background-color: #ffffff;

        &:focus {
            outline: none;
            box-shadow: none;
            border-radius: 0;
        }
    }

    .search-location {
        img {
            width: 16px;
        }
    }

    .dropdown-info {
        min-width: 350px;
        right: 0;
    }

    .close-current-layer-view {
        z-index: 10;
        right: 8px;
        top: 5px;
    }

    .dropdown-base-map {
        right: 0;

        .dropdown-item {
            cursor: pointer;
        }
    }

    .dropdown-search {
        right: 45px;
        top: -2px;

        img {
            width: 32px;
            height: 32px;

            &:hover {
                cursor: pointer;
                transform: scale(1.1);
                transition: all 0.3s ease;
            }
        }

        input {
            width: 300px;
            border: 0;

            &::placeholder {
                font-size: 14px;
            }
        }
    }
}

.filter-information-popup-container div .body-group {
    min-height: 50px !important;
    height: auto;
    max-height: 460px !important;
}

@media (max-width: 767px) {
    .tools-box-right-container {
        top: -42px;
        right: 2px;
        z-index: 50;
        display: flex;
        background-color: #ffffff;
        border-radius: 4px;
        height: 40px;
        align-items: center;
    }
    .tools-box-right-container .dropdown-info {
        right: -87px !important;
    }
    .map-tool-panel-container {
        top: 0px !important;
        width: 320px !important;
    }
    .filter-information-popup-container {
        top: 85px !important;
        right: -10px !important;
        width: 320px !important;
        border-radius: 2px !important ;
        min-height: 50px !important;
        height: auto !important;
    }

    .tools-box-right-container .dropdown-search {
        right: -166px;
        top: -45px;
        z-index: 200;
    }
}