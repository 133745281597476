.cb {clear: both;}
.report-content-box {
    .close-icon {
        position: relative;
        width: 30px;
        height: 30px;
        cursor: pointer;
        top: 2px;
        left: 2px;
        z-index: 3000;
    }
    .search-box {
        width: 100%;
        min-height: 30px;
        border-bottom: solid 1px #1b1b1b ;
        padding: 0px 10px 10px 10px;

        .select-chart-box {
            width: 50%;
            padding-right: 1%;
            float: left;
        }
        .select-chart-box select {
            width: 99%;
            border: solid 1px #CCCCCC;
            height: 30px;
            border-radius: 3px;
        }

        .select-carrer-box {
            width: 20%;
            padding-right: 1%;
            float: left;
        }
        .select-carrer-box select {
            width: 98%;
            border: solid 1px #CCCCCC;
            height: 30px;
            border-radius: 3px;
        }

        .select-from-year-box {
            width: 12%;
            float: left;
        }
        .select-from-year-box select {
            width: 90%;
            border: solid 1px #CCCCCC;
            height: 30px;
            border-radius: 3px;
        }
        .select-to-year-box {
            width: 12%;
            float: left;
        }
        .select-to-year-box select {
            width: 90%;
            border: solid 1px #CCCCCC;
            height: 30px;
            border-radius: 3px;
        }
        .search-view-box {
            width: 6%;
            float: left;
        }
    }

    .report-render-box {
        width: 100%;
        min-height: 300px;
        max-height: 500px;
        height: auto;
    }

    .chart-box {
        width: 49%;
        min-height: 300px;
        padding: 0px 10px 10px 10px;
        float: left;
    }
    .result-box {
        min-height: 300px;
        width: 50%;
        padding: 0px 10px 10px 0px;
        float: left;
    }
    .cb {clear: both;}
}

.report-table-only-box {
    min-height: 100px;
    padding: 10px;
}

.report-no-data {
    padding-top: 60px;
    min-height: 30px;
    text-align: center;
}

.table-data-box {
    margin-top: 10px;
    height: 400px;
    max-height: 400px;
    overflow: auto;
    font-size: 12px;
}

.table-data-box table {
    width: 100%;
    border: solid 1px #CCCCCC;
}

.table-data-box table tr {
    border-bottom: solid 1px #CCCCCC;
}

.table-data-box table tr th{
    border-left: solid 1px #CCCCCC;
    padding: 2px 2px 2px 2px;
    text-align: center;
    background-color: #083A5E;
    color: #FFFFFF;
    font-weight: normal;
}

.table-data-box table tr td{
    border-left: solid 1px #CCCCCC;
    padding: 2px 2px 2px 2px;
    font-weight: normal;
}

@media (max-width: 767px) {
    .search-box {
        width: 100% !important;
    }
    .select-chart-box {
        width: 99% !important;
        margin-bottom: 10px;
    }
    .select-carrer-box {
        width: 100% !important;
        margin-bottom: 10px;
    }
    .select-from-year-box {
        width: 38% !important;
    }
    .select-to-year-box {
        width: 38% !important;
    }
    .search-view-box {
        width: 21% !important;
    }
    .search-view-box button {
        width: 100% !important;
    }
    .report-render-box {
        width: 100% !important;
    }
    .report-content-box .chart-box {
        width: 100% !important;
    }
    .report-content-box .table-data-box {
        width: 98% !important;
        padding-left: 3%;
    }
}