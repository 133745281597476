.document-box{
  min-height: 30px; background-color: #FFAF18;
}

.document-item {
  min-height: 25px;
  padding-left: 10px;
}

.document-item  a {
  font-weight: normal;
  color: #1d2124;
}

.content-records {
  min-height: 20px !important;
  height: auto !important;
}

.cb {clear: both;}