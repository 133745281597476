:root {
  --animation-duration-left-navbar: 0.2s;
}

.planning-map-view-container {
  .map-tools-container {
    width: 80px;
    top: 13px;
    right: 22px;
    z-index: 1;
  }

  .left-custom-navbar {
    top: 0px;
    min-width: 320px;
    height: 100%;
    left: 0px;
    transition-duration: var(--animation-duration-left-navbar);
    z-index: 10;

    &.show-map-result {
      width: 90%;
    }

    .custom-container {
      background-color: white;
      padding-bottom: 40px;

      .navbar-custom {
        .header-navbar-custom {
          padding-top: 3px;
          background-color: #1075bd;

          .btn {
            border-radius: 0;
            background-color: white;
            color: black;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            padding: 10px 9px;
            border: 0;
            pointer-events: none;
          }

          .btn[class$="collapsed"] {
            background-color: #1075bd;
            color: white;
            pointer-events: unset;
          }

          .btn:focus {
            box-shadow: none;
          }

          .btn-hide-left-navbar {
            right: 0px;
            padding-left: 15px;
            padding-right: 15px;
            background-color: unset;
            pointer-events: unset;
          }
        }

        .header-navbar-custom-icons-style {
          right: 0px;
          width: 50px;
          background-color: rgba(255, 255, 255, 0);

          button {
            margin-top: 15px;
            background-color: white;
            border: 0px;
            width: 100%;

            .information-circle-warp {
              margin-left: 4px;
              width: 28px;
              padding: 0;
              padding-bottom: 2px;
            }
          }
        }
      }

      .guide-user {
        padding-top: 12px;
        box-sizing: border-box;
        width: 90%;
        border-top: 1px solid #c5c5c5;
        bottom: 0px;
        right: 15px;
        height: 40px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #747474;
        transition-duration: var(--animation-duration-left-navbar);

        svg {
          cursor: pointer;
        }

        span {
          transition-duration: var(--animation-duration-left-navbar);
          cursor: pointer;
        }
      }

      .guide-user-hide {
        text-align: center;
        width: 12%;
        right: 6px;
      }
    }

    &.hide-left-navbar {
      .show-map-result {
        width: 395px;
      }
    }
  }

  .hide-left-navbar {
    left: -340px;
  }

  .modal-content-custom {
    border-radius: 0;

    .modal-content-header {
      background-color: #1075bd;
      text-transform: uppercase;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: white;
      padding: 15px 0 14px 0;

      svg {
        position: absolute;
        top: 15px;
        right: 15px;
      }
    }
  }
}

.ol-attribution.ol-unselectable.ol-control.ol-uncollapsible {
  display: none;
}
