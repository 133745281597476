.map__search-bar-container {
  .map__map-maker-icon {
    color: #fd6c6c;
  }

  .map__github-icon {
    font-size: 24px;
  }

  .map__github-link {
    color: #262626;
    font-size: 20px;
  }

  .map__github-link:hover,
  .map__github-link:active {
    color: #4078c0;
    text-decoration: none;
  }

  .map__suggestion-icon {
    margin-right: 8px;
  }

  .map__search-input-container {
    position: relative;
  }

  .map__clear-button,
  .map__clear-button:active,
  .map__clear-button:focus {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    outline: none;
    font-weight: 600;
    color: #999;
  }

  .map__autocomplete-container {
    border-bottom: honeydew;
    border-left: honeydew;
    border-right: honeydew;
    border-top: 1px solid #e6e6e6;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 0 0 2px 2px;
    position: absolute;
    z-index: 1;
  }

  .map__suggestion-item {
    padding: 8px;
    text-align: left;
    background-color: #fff;
    cursor: pointer;
  }

  .map__suggestion-item--active {
    background-color: #fafafa;
  }

  .map__dropdown-footer {
    display: flex;
    justify-content: flex-end;
    padding: 4px;
  }

  .map__dropdown-footer-image {
    display: inline-block;
    width: 150px;
  }

  .map__spinner {
    color: #18bc9c;
    font-size: 30px;
  }

  .map__error-message {
    color: red;
  }

  .map__geocode-result-header {
    font-size: 20px;
    color: #222222;
  }
}
