.thumbnail-preview-container {
    margin-bottom: 20px;

    .thumbnail-container {
        &:hover {
            .thumbnail-detail-front {
                display: flex;
            }
        }

        .thumbnail-image {
            height: 180px;
        }

        .thumbnail-detail-front {
            top: 0;
            background-color: rgba(99, 97, 97, 0.671);
            display: none;
            align-items: center;
            justify-content: center;

            .thumbnail-button-detail {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 12px;
                color: #A4A4A4;
                padding: 8px 10px 9px 10px;
                border-radius: 3px;
                background: #FFFFFF;
                border: 1px solid #C5C5C5;
                box-sizing: border-box;
            }
        }
    }

    .preview-title {
        font-weight: 500;
        font-size: 0.875rem;
        display: flex;
        align-items: center;
        text-align: center;
        background-color: #CC0902;
        text-decoration: none;
        overflow: hidden;
        height: 60px;

        a {
            color: #FFFFFF;
        }
    }
}