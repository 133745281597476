.map-note-container {
  position: absolute;
  right: 0px;
  bottom: 0px;
}
.map-note-box {
  position: fixed;
  right: 0px;
  bottom: 0px;
  width: 220px;
  min-height: 100px;
  max-height: 600px;
  height: auto;
  background-color: #FFFFFF;
  border: solid 1px #CCCCCC;
  border-top-left-radius: 3px;
}

.open-map-note{
  display: block;
}

.close-map-note{
  display: none;
}

.map-note-box .note-header {
  border-bottom: solid 1px #CCCCCC;
  padding: 5px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  color: #FFFFFF;
  font-size: 14px;
  background-color: #1075BD;
  border-top-left-radius: 3px;
}
.map-note-box .note-map-name {
  padding: 5px;
  border-bottom: solid 1px #CCCCCC;
  min-height: 20px;
  height: auto;
}
.map-note-box .note-header .close-icon{
  position: absolute;
  top: 5px;
  left: 5px;
  cursor: pointer;
}

.map-note-box .note-content {
  text-align: center;
  min-height: 80px;
  max-height: 400px !important;
  overflow-y: auto;
}
.map-note-box .note-content img {
  width: 98%;
  max-width: 98%;
  height: auto;
}
