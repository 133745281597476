.document-box{
  min-height: 30px; background-color: #FFAF18;
}

.report-action-box{
  min-height: 30px; background-color: #1075BD;
  cursor: pointer;
}

.document-item {
  min-height: 25px;
  padding-left: 10px;
}

.document-item  a {
  font-weight: normal;
  color: #1d2124;
}

.MuiTreeView-root a {
  color: #1d2124;
  font-weight: normal;
}