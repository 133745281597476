.plan-intro-title {min-height: 30px; font-weight: bold; padding-bottom: 10px; text-align: center}
.plan-intro-content {min-height: 30px; padding-bottom: 10px;}
.menu-schedule {margin-top: 10px;}
.map-title {background-color: #359AE1; font-size: 14px; height: 40px; padding-top: 10px; text-transform: uppercase; color: #FFFFFF; text-align: center;}
.map-search-entry {min-height: 300px; background-color: #FFFFFF; border: solid 1px #CCCCCC; text-align: center; padding: 0 20px 0px 20px;}
.map-entry-access-db {padding-top: 20px; font-size: 16px;}
.map-entry-download-db {padding-top: 20px;}

@media (max-width: 767px) {
  #planning-intro-content {
    display: none;
  }
  .wrapper {
    width: 93% !important;
  }
  .wrapper #leftPanel {
    min-height: 20px !important;
  }

  .MuiTreeView-root {
    min-height: 20px !important;
  }

  .records-management .planning-records-result .records-table .title {
    word-wrap: break-word;
  }
}