.preview-content-header {
    min-height: 30px;
    padding-top: 10px;
    border-bottom: solid 1px #CCCCCC;
}
.preview-modal-title {
    min-height: 30px;
    padding-left: 10px;
}
.preview-close-button {
    position: absolute;
    right: 0px;
    cursor: pointer;
    width: 30px;
    height: 30px;
}

.preview-content-box {
    padding: 5px;
    text-align: center;
}
.preview-content-box img {
    max-width: 100%;
    max-height: 100% !important;
}